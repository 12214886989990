import * as React from "react";
import GoogleLogin from "react-google-login";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import { useParams, useSearchParams, To } from "react-router-dom";
import { registerGoogle } from "../../services/identityService";
import RedirectDialog from "../RedirectDialog.tsx";

export default function GoogleRegister({ setOtherError, login }) {
  const params = useParams();
  const [queryParams] = useSearchParams();
  const redirectUrl =
    queryParams.get("redirectUrl") ?? "https://join.sharprank.com/appstore";
  const [redirecting, setRedirecting] = useState(false);
  const [secondaryText, setSecondaryText] = useState(<></>);

  const responseGoogle = async (googleLoginObject) => {
    try {
      setOtherError("");
      const referralCode =
        queryParams.get("referralCode") ?? params.referralCode;
      const serverResponse = await login(googleLoginObject, referralCode);
      console.log(serverResponse);
      const text = !serverResponse.leagueName ? (
        <></>
      ) : (
        <Typography sx={{ fontSize: 16 }}>
          Invited to {serverResponse.leagueName}
        </Typography>
      );
      debugger;
      setSecondaryText(text);
      setTimeout(() => window.location.replace(redirectUrl), 2000);
      setRedirecting(true);
    } catch (error) {
      handleFailure(error);
    }
  };

  const handleFailure = (error: any) => {
    debugger;
    if (error?.response?.status == 410) {
      setOtherError(`Invite link has already been used.`);
      const text = (
        <Typography sx={{ fontSize: 16 }}>
          Invite link has already been used.
        </Typography>
      );
      setSecondaryText(text);
      setRedirecting(true);
      setTimeout(() => window.location.replace(redirectUrl), 2000);
      return;
    }
    if (error.error == "popup_closed_by_user") return;
    if (error.error == "user already exists") {
      setTimeout(() => window.location.replace(redirectUrl), 2000);
      setRedirecting(true);
      return;
    }

    if (error.response?.data?.message == "Invalid referral code.") {
      setOtherError(`Invalid referral code: ${params.referralCode}`);
      return;
    }
    console.error(error);
  };

  return (
    <>
      <GoogleLogin
        clientId="800260671259-3o743u7kmg1k0qibutv3gr24o596rftk.apps.googleusercontent.com"
        buttonText="Continue with Google"
        onSuccess={responseGoogle}
        onFailure={handleFailure}
        cookiePolicy={"single_host_origin"}
        scope="profile email"
        prompt="select_account"
        isSignedIn={false}
        className="google-button"
      />
      <RedirectDialog
        open={redirecting}
        secondaryText={secondaryText}
      ></RedirectDialog>
    </>
  );
}
